import React, { useState } from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/fanzine.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const Zine = ({ zine: { title, front, back, pdf }}) => (
  <div>
    <div className='row-spaced'>
      <h3>{title}</h3>
      {pdf && (
        <h3>
          <a href={pdf} target='_blank'>PDF</a>
        </h3>
      )}
    </div>
    <img src={front} />
    <img src={back} />
  </div>
);

const ShowMoreButton = ({ onClick }) => (
  <div onClick={onClick} className={styles.showMoreContainer}>
    <a className={styles.showMore}>SHOW MORE</a>
  </div>
)

export default ({ data }) => {
    const [limit, setLimit] = useState(5);
    const [zines, setZines] = useState((data.allFanzineJson.edges || []).slice(0, limit));
    const showMore = () => {
      setZines(data.allFanzineJson.edges.slice(0, limit + 5));
      setLimit(limit + 5);
    }

    return (
        <Layout maxWidth={1000}>
            <Container>
                <h1 className='page_title'>
                    <img
                        className='page_title_image'
                        style={{ marginBottom: 10}}
                        src="/images/home/logo_zine.png"
                        alt="'zine"
                    />
                  <img src="/fanzine/header.jpg" style={{ marginBottom: 0}} />
                </h1>
              <p style={{}}>Since its first issue in 2017,
                COdA has eversince published a fanzine in a free A4 format,
                you'll find it at the weirdest gigs, finest restaurants and filthiest gutters.
                They include drawings, interviews, poems and other expressive urges from our crew and beyond.
                The latest issue is included in every order.</p>

                {zines.map(({node}) => <Zine key={node.title} zine={node} />)}

                {limit < data.allFanzineJson.edges.length && (
                  <ShowMoreButton onClick={showMore}>Show more</ShowMoreButton>
                )}
            </Container>
        </Layout>
    )
};

export const query = graphql`
  query {
    allFanzineJson {
      edges {
        node {
          title
          front
          back
          pdf
        }
      }
    }
  }
`;
